/* General Styles */
body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(to right, #387383, #2C9BCA);
    color: #fff;
  }
  
  /* Container Styles */
  .email-verification-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
  }
  
  /* Card Styles */
  .card {
    background: #fff;
    color: #333;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
  }
  
  .card h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .landing-page-link:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  