@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');


.failed-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    text-align: center;
}

.failed-card h1 {
    color: #B31E1E;
    font-size: 24px;
    margin-bottom: 10px;
}

.failed-card p {
    color: #555;
    font-size: 16px;
    margin-bottom: 15px;
}

.failed-card strong {
    color: #000;
    font-size: 20px;
    margin-bottom: 10px;
}